import React from 'react';

import PageWrapper from '../components/PageWrapper';
import { Box } from '../components/Core';
import Contact from '../sections/others/Contact';
import Hero from './../sections/landing1/Hero';
import { Element } from 'react-scroll';

const PageNotFound = () => {
	return (
		<>
			<PageWrapper darkTheme>
				<Box>
					<Hero />
					{/* <Element name="pagenotfound"> */}
					{/* <div>PAGE NOT FOUND</div> */}
					{/* </Element> */}
				</Box>
			</PageWrapper>
		</>
	);
};

export default PageNotFound;
